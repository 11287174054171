import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Button from "../components/button"
import FullScreenText from "../components/fullScreenText"
import Layout from "../components/layout"
import { colors } from "../utils/siteVars"

const StyledCancelledPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 55px - 2rem);
`

const CancelledPage = () => {
  const query = useStaticQuery(graphql`
    query cancelQuery {
      bgImg: file(relativePath: { eq: "bg_index.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const c = "rgba(30,50,100,0.9)"
  const layeredBG = [
    `linear-gradient(${c}, ${c})`,
    query.bgImg.childImageSharp.fluid,
  ]
  return (
    <Layout title="Cancelled" bg={layeredBG}>
      <StyledCancelledPage>
        <FullScreenText>
          <h1>
            The order <br />
            <span>was cancelled!</span>
          </h1>
          <p>Try again if you want to.</p>
          <div className="button-container">
            <Link to="/make">
              <Button bg={colors.secondary} color={colors.dark}>
                Design tshirt
              </Button>
            </Link>
          </div>
        </FullScreenText>
      </StyledCancelledPage>
    </Layout>
  )
}

export default CancelledPage
